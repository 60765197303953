import { WorldMapStyled } from "./styled/worldMap.styled";
import useIconName from "../../shared/utils/hooks/useIconName";
import Icon from "../../shared/partnerComponents/icons/icon";
import { Col, Row } from "antd";
import CustomCountUp from "../../shared/partnerComponents/countUp";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const WorldMap = ({ widgets, countries }) => {
  const worldMap = widgets?.find((item) => item.code === "worldMap");

  return (
    <WorldMapStyled className="worldMap">
      <Row justify="center" gutter={[30, 30]} className="worldMap-content">
        {worldMap &&
          worldMap?.contents?.map((item, index) => {
            const iconName = useIconName(item.id);

            return (
              <Col xs={8} sm={8} md={8} lg={8} xl={4} xxl={4} key={index}>
                <div className="worldMap-content-ic">
                  <div className="worldMap-content-icons">
                    <Icon name={`icon-${iconName}`} id="icon" />
                  </div>
                  {!item?.title?.split(" ")?.[0].indexOf("7/24") ? (
                    <div className="worldMap-content-title">
                      <span>{item.title.split(" ")?.[0]}</span>
                      <p>{item.title.substring(item.title.indexOf(" ") + 1)}</p>
                    </div>
                  ) : (
                    <CustomCountUp
                      startCount={0}
                      endCount={
                        item?.title?.split(" ")?.[0].indexOf("+") > -1
                          ? parseFloat(item?.title?.split(" ")?.[0]?.replace("+", " "))
                          : parseFloat(item?.title?.split(" ")?.[0])
                      }
                      durationCount={8}
                      containerClassName="worldMap-content-title"
                      label={item.title.substring(
                        item?.title?.indexOf(" ") + 1
                      )}
                    />
                  )}
                </div>
              </Col>
            );
          })}
      </Row>
      <div className="worldMap-world">
        <ComposableMap
          
          projection="geoEqualEarth"
          projectionConfig={{
            rotate: [0, 0, 0],
            scale: 0,
          }}
          style={{
            verticalAlign: "middle",
            width: "100%",
            height: "auto",
          }}
        >
          <Geographies geography={"/features.json"} >
            {({ geographies }) =>
              geographies?.map((geo, proj) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  stroke="#D6D6DA"
                  projection={proj}
                  style={{
                    default: {
                      fill: "#EEE",
                    },
                    hover: {
                      fill: "#365097",
                    },
                    pressed: {
                      fill: "#365080",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {countries?.map(
            ({ name, latitude, longitude, showOnMap }) =>
              showOnMap && (
                <Marker key={name} coordinates={[latitude, longitude]}>
                  <g
                    fill="transparent"
                    stroke="#FF5533"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(-9, -18) scale(0.75)"
                  >
                    <circle cx="12" cy="10" r="3" />
                    <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                  </g>
                  <text
                    textAnchor="middle"
                    y={15}
                    style={{ fill: "#5D5A6D" }}
                    className="text"
                  >
                    {name}
                  </text>
                </Marker>
              )
          )}
        </ComposableMap>
      </div>
    </WorldMapStyled>
  );
};
export default WorldMap;
