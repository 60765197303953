import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

// Kendi görünürlük sensörünü oluşturma
const CustomVisibilitySensor = ({ onChange, children }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          onChange(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [onChange]);

  return <div ref={ref}>{children(isVisible)}</div>;
};

const CustomCountUp = ({
  startCount = 0,
  endCount = 0,
  className,
  containerClassName,
  durationCount = 0,
  decimals,
  suffix = "",
  prefix = "",
  decimalSeperate,
  delay = 0,
  thousandSeparator,
  endFunction,
  startFunction,
  label,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={`customCountUp ${containerClassName || ""}`}>
      <CustomVisibilitySensor onChange={setVisible}>
        {(isVisible) => (
          <CountUp
            className={className || ""}
            start={startCount}
            end={isVisible ? endCount : 0}
            duration={durationCount}
            decimals={decimals}
            decimal={decimalSeperate || ""}
            delay={delay}
            separator={thousandSeparator}
            suffix={suffix}
            prefix={prefix}
            onEnd={endFunction}
            onStart={startFunction}
          />
        )}
      </CustomVisibilitySensor>
      {label && (
        <>
          <div className="customCountUp__border" />
          <p className="customCountUp__desc">{label}</p>
        </>
      )}
    </div>
  );
};

CustomCountUp.propTypes = {
  startCount: PropTypes.number,
  endCount: PropTypes.number,
  thousandSeparator: PropTypes.string,
  label: PropTypes.string,
  durationCount: PropTypes.number,
  delay: PropTypes.number,
  decimals: PropTypes.number,
  decimalSeperate: PropTypes.string,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
};

export default CustomCountUp;
