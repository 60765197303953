import styled from "styled-components";
import settingsStyled from "../../../styled/settings.styled";

const WorldMapStyled = styled.section`
  //margin-top:5%;//87px
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 127px;

  @media ${settingsStyled.m1200} {
    padding-top: 107px;
  }

  @media ${settingsStyled.m767} {
    padding-top: 87px;
  }

  @media ${settingsStyled.m479} {
    height: auto;
    padding: 62px 0px 51px 0px;
  }
  .worldMap {
    // gap: 80px;
    display: flex;
    flex-direction: row;
    &-content {
      @media ${settingsStyled.m1200} {
        padding: 0 72px;
      }

      @media ${settingsStyled.m767} {
        padding: 0 42px;
        justify-content: space-around;
      }

      @media ${settingsStyled.m479} {
        margin-bottom: 46.5px;
      }
      &-ic {
        display: flex;
        flex-direction: row;

        @media ${settingsStyled.m1200} {
          justify-content: center;
        }

        @media ${settingsStyled.m767} {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      &-icons {
        //height: 74.236px;
        & > div > svg {
          fill: #365097;
          width: 56.382px;
          height: 56.382px;
          @media ${settingsStyled.m767} {
            width: 7.217vw;
            height: 7.217vw;
          }
          @media ${settingsStyled.m479} {
            width: 7.25vw;
            height: 7.25vw;
          }
        }
      }
      &-title {
        margin-left: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 43.335px; /* 154.768% */

        @media ${settingsStyled.m767} {
          margin-left: 0px;
          text-align: center;
        }

        span {
          color: #343434;
          font-family: Inter;
          font-display: swap;
          font-size: 28px;
          @media ${settingsStyled.m767} {
            font-size: 4.436vw;
            line-height: 11.112vw;
          }
        }
        p {
          width: 200px;
          color: #5d7285;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28.89px; /* 120.375% */

          @media ${settingsStyled.m1600} {
            width: 160px;
          }

          @media ${settingsStyled.m1023} {
            font-size: 18px;
            line-height: 24.89px;
          }

          @media ${settingsStyled.m767} {
            width: 200px;
            font-size: 16px;
            line-height: 21.89px;
          }
        }
      }
    }
    &-world {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      img {
        @media ${settingsStyled.m767} {
          width: 91.795vw;
          height: 71.026vw;
        }
      }
    }
  }

  .rsm {
    &-geography {
      outline: none !important;
    }
    &-marker {
      text {
        display: none;
      }

      &:hover {
        text {
          display: block;
        }
      }
    }
  }
`;

export { WorldMapStyled };
